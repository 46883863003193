

function App() {
  return (
    <div className="details">
      דומיין זה בבעלות Matan Aviav.<br /><br />
      <b>לפרטים:</b>

      <div className="contact">
        <a href="https://www.linkedin.com/in/matanaviav/" target="_blank">LinkedIn</a>
        <div className="spacer">&bull;</div>
        <a href="matilto:matanaviav@gmail.com" target="_blank">matanaviav@gmail.com</a>
        <div className="spacer">&bull;</div>
        <a href="tel:+972528086027" target="_blank">0528086027</a>
      </div>

    </div>
  );
}

export default App;
